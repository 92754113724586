<template>
	<div class="login">
		<div class="title">
			{{i18n('159')}}
			<div class="posi" @click="zc">
				{{i18n('160')}}
			</div>
		</div>
		<div class="inpWap">
			<inputStyle v-for="(item,index) in inputData" :key="index" :label='item.label' :type='item.type'
				:keyType='item.keyType' :verification='item.verification' :errorText='item.errorText'
				:keyValue="item.value" :codeText="codeText" @getCode="getCode" :keyName='item.keyName'
				:placeholder="item.placeholder" :value='item.value' @change="change($event,index)"></inputStyle>
		</div>
		<div class="but" @click='sumb'>
			{{i18n('161')}}
		</div>
		<div class="xy">{{i18n('163')}}<span @click.stop="$router.push('/policy')">{{i18n('162')}}</span></div>
	</div>
</template>

<script>
	import {
		sendCode,
		registerPassword
	} from "@/api/user.js"
	import inputStyle from '@/components/inputStyle.vue'
	export default {
		name: "login",
		components: {
			inputStyle
		},
		data() {
			return {
				inputData: [{
						label: this.i18n('16'),
						type: 'number',
						keyType: 'phone',
						verification: 1,
						placeholderLang: '04',
						errorTextLang: '03',
						labelLang: '16',
						errorText: this.i18n('03'),
						placeholder: this.i18n('04'),
						keyName: 'text',
						value: ''
					},
					{
						label: this.i18n('10'),
						type: 'number',
						keyType: 'code',
						verification: 1,
						placeholderLang: '06',
						errorTextLang: '05',
						labelLang: '10',
						errorText: this.i18n('05'),
						placeholder: this.i18n('06'),
						keyName: 'code',
						value: '',
					},
					{
						label: this.i18n('12'),
						type: 'text',
						keyType: 'pass',
						verification: 1,
						placeholderLang: '08',
						errorTextLang: '07',
						labelLang: '12',
						errorText: this.i18n('07'),
						placeholder: this.i18n('08'),
						keyName: 'pass',
						value: '',
					},
					// {
					// 	label: this.i18n('13'),
					// 	type: 'number',
					// 	keyType: 'invitation_code',
					// 	verification: 2,
					// 	placeholderLang: '15',
					// 	errorTextLang: '14',
					// 	labelLang: '13',
					// 	errorText: '',
					// 	placeholder: this.i18n('15'),
					// 	keyName: 'invitation_code',
					// 	value: '',
					// }
				],
				time: 0,
				interval: false,
				codeText: this.i18n('09')
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		watch: {
			lang() {
				this.inputData.map(item => {
					item.errorText = this.i18n(item.errorTextLang)
					item.placeholder = this.i18n(item.placeholderLang)
					item.label = this.i18n(item.labelLang)
				})
			}
		},
		methods: {
			async sumb() {
				let params = {

				}
				let verification = true
				this.inputData.map(item => {
					if (item.verification == 2) {
						params[item.keyName] = item.value
					} else {
						item.verification = 3
						verification = false
					}
				})
				if (!verification) {
					return false
				}
				
				// await registerPassword(params)
				let {
					data
				} = await registerPassword(params)

				this.$store.commit("user/setUserInfo", data)
				this.$store.commit("user/setToken", data.token)
				this.$router.replace('/')
			},
			zc() {
				this.$emit("typeChange", 1)
			},
			change(e, index) {
				this.inputData[index] = Object.assign(this.inputData[index], e)
			},
			async getCode() {
				if (this.time != 0) {
					return false
				}
				let verification = 1
				let phone = 1
				this.inputData.map(item => {
					if (item.keyType == 'phone') {
						verification = item.verification
						phone = item.value
					}
				})
				if (verification == 2) {
					await sendCode({
						phone,
						type: 0
					})
					clearInterval(this.interval);
					this.time = 60;
					this.yzmText = this.time + 's';
					this.interval = setInterval(() => {
						if (this.time > 0) {
							this.time--
							this.codeText = this.time + 's';
						} else {
							this.time = 0
							this.codeText = this.i18n('09');
							clearInterval(this.interval);
						}
					}, 1000)
				}
			},
		},
		created() {
			let invitation_code = this.$route.query.invitation_code
			console.log("invitation_code")
			console.log(invitation_code)
			if(invitation_code){
				this.inputData.map(item => {
					if(item.keyName == 'invitation_code'){
						item.value = invitation_code
					}
				})
			}
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.xy{
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 16px;
	}
	.xy span{
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #56AB7B;
		line-height: 20px;
		font-weight: 400;
		cursor: pointer;
	}
	.but {
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		margin: 0 auto;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}

	.inpWap {
		width: 310px;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.tab {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}

	.tab .item {
		width: 100px;
		height: 38px;
		background: #fff;
		border-radius: 19px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}

	.tab .item:last-child(1) {
		margin-left: 4px;
	}

	.tab .itemAc {
		background: #56AB7B;
		color: #FFFFFF;
	}

	.title {
		text-align: center;
		margin-bottom: 32px;
		height: 30px;
		line-height: 30px;
		font-size: 24px;
		color: #1D2129;
		font-weight: 500;
		font-family: PingFangSC-Medium;
		position: relative;
	}

	.title .posi {
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #56AB7B;
		font-weight: 500;
		position: absolute;
		right: 20px;
		top: 0;
		height: 30px;
		line-height: 30px;
		cursor: pointer;
	}

	.login {
		width: 400px;
		height: 518px;
		background: #FFFFFF;
		border-radius: 20px;
		padding-top: 30px;
		box-sizing: border-box;
	}
</style>
