<template>
	<div class="loginRegistration">
		<div class="inner">
			<div class="left">
				<img src="@/assets/log.png" alt="" class="logo" v-if="type==1">
				<!-- <div class="newZc" v-else>
					<div class="">{{i18n('164')}}<span>₱50</span></div>
					<div class="">（{{i18n('165')}}）</div>
				</div> -->
				<img src="@/assets/loginRegistration/bg.png" alt="" class="bg">
			</div>
			<div class="right">
				<login v-if="type==1" @typeChange="typeChange"></login>
				<register v-if="type==2" @typeChange="typeChange"></register>
				<div class="xz">
					<img src="@/assets/loginRegistration/ios.png" alt="">
					<img src="@/assets/loginRegistration/android.png" alt="">
				</div>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import login from './components/login.vue'
	import register from './components/register.vue'
	import foot from "@/components/topBot/footer.vue"
	export default {
		name: "loginRegistration",
		components:{
			login,
			register,
			foot
		},
		data() {
			return {
				type:1   //1登陆  2注册
			}
		},
		methods: {
			typeChange(type){
				this.type=type
			}
		},
		destroyed() {
			this.$store.commit('user/setHeadShow', true)
		},
		created() {
			this.type = this.$route.query.type
			this.$store.commit('user/setHeadShow', false)
		},
		mounted() {
			this.$store.commit('user/setHeadShow', false)
		}
	};
</script>

<style scoped>
	.xz{
		margin-top: 28px;
		width: 400px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}
	.xz img{
		width: 148.71px;
		height: 46px;
		display: block;
	}
	.inner{
		background: #56AB7B;
		padding: 89px 177px 64px 167px;
		display: flex;
		justify-content: space-between;
	}
	.logo{
		padding-left: 12px;
		/* width: 140px; */
		height: 55px;
		display: block;
	}
	.bg{
		width: 498px;
		height: 354px;
		border-radius: 8px;
		display: block;
		margin-top: 50px;
	}
	.newZc{
		width: 502px;
		height: 137.47px;
		background: url(../../assets/loginRegistration/newZc.png)no-repeat;
		background-size: 100% 100%;
	}
	.newZc div{
		text-align: center;
	}
	.newZc div:nth-child(1){
		font-family: PingFangSC-Regular;
		font-size: 22px;
		color: #000000;
		padding-top: 55px;
	}
	.newZc div:nth-child(1) span{
		color: #F23725;
	}
	.newZc div:nth-child(2){
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #56AB7B;
		font-weight: 400;
	}
</style>