<template>
	<div class="inputStyle">
		<div class="input">
			<div class="left">
				{{label}}
			</div>
			<div class="center">
				<input :type="type" v-model="value" @blur="verificationFun" :placeholder="placeholder">
			</div>
			<div class="right" v-if="keyType=='code'" style="cursor: pointer;" @click="getCode">
				{{codeText}}
			</div>
		</div>
		<div class="errorText">
			<span v-if="verification==3">{{errorText}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: "inputStyle",
		//type 输入框类型    keyType 值类型   verification= 1没有验证 2已验证成功 3验证失败
		props: ['type', 'keyType', 'verification', 'errorText', 'keyName', 'label', 'placeholder', 'codeType', 'codeText',
			'keyValue'
		],
		data() {
			return {
				value: ""
			}
		},
		watch: {
			keyValue() {
				if (this.keyValue.indexOf('63') != -1) {
					this.value = this.keyValue.substring(2)
				} else {
					this.value = this.keyValue
				}
			}
		},
		methods: {
			//电话号码校验
			phoneJy() {
				let phone = JSON.parse(JSON.stringify(this.value))
				if (phone.length != 10 && phone.length != 11) {
					return {
						'phone': phone,
						'verification': 3
					}
				}
				if (phone.length == 10) {
					phone = "0" + phone
				}
				return {
					'phone': '63' + phone,
					'verification': 2
				}
			},
			verificationFun() {
				let verification = 1
				let value = 1
				if (this.errorText == '') {
					verification = 2
					value = this.value
				} else {
					if (this.keyType == 'phone') {
						//电话号码校验
						let phoneData = this.phoneJy()
						verification = phoneData.verification
						value = phoneData.phone
					} else {
						value = this.value
						if (this.value == '') {
							verification = 3
						} else {
							verification = 2
						}
					}
				}
				this.$emit('change', {
					verification,
					value
				})
			},
			getCode() {
				this.$emit('getCode')
			}
		},
		created() {
			this.value = this.keyValue
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.inputStyle {
		width: 100%;
	}

	.input {
		width: 100%;
		height: 44px;
		border: 1px solid rgba(170, 181, 195, 1);
		border-radius: 24px;
		display: flex;
		align-items: center;
		padding: 0 20px 0 16px;
		box-sizing: border-box;
	}

	.inputError {
		border: 1px solid rgba(242, 55, 37, 1);
	}

	.errorText {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #F23725;
		font-weight: 500;
		padding-left: 15px;
		padding-top: 4px;
		height: 30px;
		box-sizing: border-box;
	}

	.input .left {
		width: 80px;
		white-space: nowrap;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		font-weight: 500;
	}

	.input .center {
		flex: 1;
	}

	.input .center input {
		width: 100%;
		line-height: 44px;
		height: 44px;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #1D2129;
		font-weight: 400;
	}

	.input .right {
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #56AB7B;
		text-align: center;
		font-weight: 500;
		padding-left: 5px;
		white-space: nowrap;
	}
</style>
