<template>
	<div class="login">
		<div class="title">
			{{i18n('154')}}
			<div class="posi" @click="zc">
				{{i18n('155')}}
			</div>
		</div>
		<div class="tab">
			<div :class="type=='phone'?'item itemAc':'item'" @click="type='phone'">
				{{i18n('156')}}
			</div>
			<div :class="type=='paw'?'item itemAc':'item'" @click="type='paw'">
				{{i18n('157')}}
			</div>
		</div>
		<div class="inpWap">
			<inputStyle v-for="(item,index) in inputData[type]" :key="index" :label='item.label' :type='item.type'
				:keyType='item.keyType' :verification='item.verification' :errorText='item.errorText'
				:keyValue="item.value" :codeText="codeText" @getCode="getCode" :keyName='item.keyName'
				:placeholder="item.placeholder" :value='item.value' @change="change($event,index)"></inputStyle>
		</div>
		<div class="but" @click='loginSumb'>
			{{i18n('154')}}
		</div>
		<div class="wj">
			<span @click="$router.push('/resetPassword')">{{i18n('158')}}</span>
		</div>
	</div>
</template>

<script>
	import {
		loginPhone,
		loginPassword,
		sendCode
	} from "@/api/user.js"
	import inputStyle from '@/components/inputStyle.vue'
	export default {
		name: "login",
		components: {
			inputStyle
		},
		data() {
			return {
				type: 'phone', //phone快捷登陆  paw密码登陆
				inputData: {
					phone: [{
							label: this.i18n('16'),
							type: 'number',
							keyType: 'phone',
							verification: 1,
							placeholderLang: '04',
							errorTextLang: '03',
							labelLang: '16',
							errorText: this.i18n('03'),
							placeholder: this.i18n('04'),
							keyName: 'phone',
							value: ''
						},
						{
							label: this.i18n('10'),
							type: 'number',
							keyType: 'code',
							verification: 1,
							placeholderLang: '06',
							errorTextLang: '05',
							labelLang: '10',
							errorText: this.i18n('05'),
							placeholder: this.i18n('06'),
							keyName: 'code',
							value: '',
						}
					],
					paw: [{
							label: this.i18n('16'),
							type: 'number',
							keyType: 'phone',
							verification: 1,
							placeholderLang: '04',
							errorTextLang: '03',
							labelLang: '16',
							errorText: this.i18n('03'),
							placeholder: this.i18n('04'),
							keyName: 'text',
							value: ''
						},
						{
							label: this.i18n('11'),
							type: 'text',
							keyType: 'paw',
							verification: 1,
							placeholderLang: '08',
							errorTextLang: '07',
							labelLang: '11',
							errorText: this.i18n('07'),
							placeholder: this.i18n('08'),
							keyName: 'pass',
							value: ''
						}
					]
				},
				time: 0,
				interval: false,
				codeText: this.i18n('09')
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		watch: {
			lang() {
				this.inputData.phone.map(item => {
					item.errorText = this.i18n(item.errorTextLang)
					item.placeholder = this.i18n(item.placeholderLang)
					item.label = this.i18n(item.labelLang)
				})
				this.inputData.paw.map(item => {
					item.errorText = this.i18n(item.errorTextLang)
					item.placeholder = this.i18n(item.placeholderLang)
					item.label = this.i18n(item.labelLang)
				})
			}
		},
		methods: {
			async loginSumb() {
				let params = {

				}
				let verification = true
				this.inputData[this.type].map(item => {
					if (item.verification == 2) {
						params[item.keyName] = item.value
					} else {
						item.verification = 3
						verification = false
					}
				})
				if (!verification) {
					return false
				}
				let loginData = false
				if (this.type == 'phone') {
					let {
						data
					} = await loginPhone(params)
					loginData = data
				} else {
					let {
						data
					} = await loginPassword(params)
					loginData = data
				}
				this.$store.commit("user/setUserInfo", loginData)
				this.$store.commit("user/setToken", loginData.token)
				this.$router.go(-1)
			},
			zc() {
				this.$emit("typeChange", 2)
			},
			change(e, index) {
				console.log(e)
				this.inputData[this.type][index] = Object.assign(this.inputData[this.type][index], e)
			},
			async getCode() {
				if (this.time != 0) {
					return false
				}
				let verification = 1
				let phone = 1
				this.inputData[this.type].map(item => {
					if (item.keyName == 'phone') {
						verification = item.verification
						phone = item.value
					}
				})
				if (verification == 2) {
					await sendCode({
						phone,
						type: 1
					})
					clearInterval(this.interval);
					this.time = 60;
					this.yzmText = this.time + 's';
					this.interval = setInterval(() => {
						if (this.time > 0) {
							this.time--
							this.codeText = this.time + 's';
						} else {
							this.time = 0
							this.codeText = this.i18n('09');
							clearInterval(this.interval);
						}
					}, 1000)
				}
			},
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.but {
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		margin: 0 auto;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}

	.wj {
		width: 310px;
		margin: 0 auto;
		text-align: right;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #56AB7B;
		font-weight: 500;
		padding-top: 20px;
	}

	.wj span {
		cursor: pointer;
	}

	.inpWap {
		width: 310px;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.tab {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}

	.tab .item {
		width: 100px;
		height: 38px;
		background: #fff;
		border-radius: 19px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}

	.tab .item:last-child(1) {
		margin-left: 4px;
	}

	.tab .itemAc {
		background: #56AB7B;
		color: #FFFFFF;
	}

	.title {
		text-align: center;
		margin-bottom: 32px;
		height: 30px;
		line-height: 30px;
		font-size: 24px;
		color: #1D2129;
		font-weight: 500;
		font-family: PingFangSC-Medium;
		position: relative;
	}

	.title .posi {
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #56AB7B;
		font-weight: 500;
		position: absolute;
		right: 20px;
		top: 0;
		height: 30px;
		line-height: 30px;
		cursor: pointer;
	}

	.login {
		width: 400px;
		height: 460px;
		background: #FFFFFF;
		border-radius: 20px;
		padding-top: 30px;
		box-sizing: border-box;
	}
</style>
